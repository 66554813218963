import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CalendarEntry,
  ClickEvent,
  EventInternal,
  RangeSet,
} from '../../model/calendar';
import Popover from 'bootstrap/js/dist/popover.js'
import { Utils } from "../../utils/lesson-utils";
import { TranslateService } from '@ngx-translate/core';
import { Dates } from 'src/app/utils/calendar-utils';

@Component({
  selector: 'app-week-calendar',
  templateUrl: './week-calendar.component.html',
  styleUrls: ['./week-calendar.component.scss'],
})
export class WeekCalendarComponent implements OnInit, AfterViewInit, OnDestroy {

  lessons = {
    en: {
      label: 'en',
      short: 'EN',
      long: 'English'
    },
    enCh: {
      label: 'en.ch',
      short: 'CH',
      long: 'English Children'
    },
    enBiz: {
      label: 'en.bs',
      short: 'BIZ',
      long: 'English Business'
    },
    sp: {
      label: 'sp',
      short: 'ES',
      long: 'Spanish'
    }
  }

  private MINUTE_LONG = 1000 * 60;
  private HOUR_LONG = this.MINUTE_LONG * 60;
  private DAY_LONG = this.HOUR_LONG * 24;
  private EVENT_WIDTH = 90;

  @ViewChild('hoursScroll', { static: true }) hoursScrollRef: ElementRef;
  @ViewChild('daysScroll', { static: true }) daysScrollRef: ElementRef;
  @ViewChild('gridScroll', { static: true }) gridScrollRef: ElementRef;
  @ViewChild('calendar', { static: true }) caledar: ElementRef;

  private _events: CalendarEntry[];
  private _currentDate: Date;
  private _weekDates: Date[] = [];
  private _weekDayEndings: Date[];
  private _activeDay: number;
  private _currentTimeAsProgress: number;
  _dayEvents: Array<Array<EventInternal>> = [];

  @Input() showFilter = false;
  @Input() loading: boolean;
  @Input()
  set events(events: CalendarEntry[]) {
    this._events = events;
    this.calculateEntries();
  }

  set currentDate(date: Date) {
    this._currentDate = date;
    this.setupDate();
    this.calculateEntries();
    this.dateUpdate.emit(date);
  }
  get currentDate(): Date {
    return this._currentDate;
  }

  @Output()
  dateUpdate = new EventEmitter<Date>();

  @Output()
  timeSelect = new EventEmitter<ClickEvent>();
  @Output()
  openFilterModal = new EventEmitter<void>();

  popover: Popover
  highlightedDate: Date
  highlightedHour: string

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.currentDate = new Date();
  }

  ngOnDestroy(){
    this.mouseLeave()
  }

  mouseEnter(ev: EventInternal, evM: MouseEvent){
    if (!this.isMobile() && ev.relatedEntry.relatedObject.schedule) {
      const dateNow =  new Date()
      const dateEvent = ev.relatedEntry.dateFrom
      let template = '', title = ''

      const timeFrom = ev.relatedEntry.dateFrom?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      const timeTo = ev.relatedEntry.dateTo?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })

      const schedule = ev.relatedEntry.relatedObject.schedule
      const course = this.getCourseName(schedule.name, ev.relatedEntry.classes[0])

      if (ev.classes === "schedule"){
        this.translateService.get(dateNow > dateEvent ? 'COL.COURSES.CALENDAR.MODAL.PAST' : 'COL.COURSES.CALENDAR.MODAL.BOOKED').subscribe((value) => title = value)
        const stage = this.getStage(schedule.name)
        const firstName = this.getStudentName(ev)
        template = `<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-inner">${title}</br>${timeFrom}-${timeTo} ${course}</br>Stage ${stage}</br>${firstName}</div></div>`
      }
      else if (ev.classes === "worktime"){
        this.translateService.get('COL.COURSES.CALENDAR.MODAL.AVAILABLE').subscribe((value) => title = value)
        const competences = this.getCompetences(schedule.name)
        template = `<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-inner">${title}</br>${timeFrom}-${timeTo} ${course}</br>${competences}</br></div></div>`
      }
      this.popover = Popover.getOrCreateInstance(evM.target, {title: title, template: template, container: 'body', customClass: 'p-2 xsmall lh-lg text-black'})
      this.popover.show()
    }
  }

  mouseLeave(){
    this.popover?.dispose()
    this.popover = null
  }

  isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }

  beginingOfDay(date: Date): Date {
    const res = new Date(date);
    res.setHours(0);
    res.setMinutes(0);
    res.setSeconds(0);
    res.setMilliseconds(0);
    return res;
  }

  endingOfDay(date: Date): Date {
    const res = new Date(date);
    res.setHours(23);
    res.setMinutes(59);
    res.setSeconds(59);
    res.setMilliseconds(999);
    return res;
  }

  setupDate() {
    if (!this.currentDate) {
      return;
    }
    const nowDate = new Date();
    const currentDayNumber = this.currentDate.getDay();

    let firstDay = new Date();
    firstDay.setTime(
      this.currentDate.getTime() - currentDayNumber * this.DAY_LONG
    );
    firstDay = this.beginingOfDay(firstDay);

    let lastDay = new Date();
    lastDay.setTime(firstDay.getTime() + 7 * this.DAY_LONG);
    lastDay = this.endingOfDay(lastDay);

    this._activeDay =
      nowDate.getTime() > firstDay.getTime() &&
      nowDate.getTime() < lastDay.getTime()
        ? currentDayNumber
        : (this._activeDay = -1);

    this._weekDates = [];
    this._weekDayEndings = [];
    for (let i = 0; i < 7; i++) {
      let dayDate = new Date();
      // move 1 day forward and then half a day to be sure that the days border was crossed
      dayDate.setTime(
        firstDay.getTime() + (i * this.DAY_LONG + this.HOUR_LONG * 12)
      );

      dayDate = this.beginingOfDay(dayDate);
      this._weekDates[i] = dayDate;
      dayDate = this.endingOfDay(dayDate);
      this._weekDayEndings[i] = dayDate;
    }

    this._currentTimeAsProgress = this.timeAsProgress(nowDate);
  }

  calculateEntries() {
    this._dayEvents = [[], [], [], [], [], [], []];
    if (!this._events || !this._weekDates || !this._weekDayEndings) {
      return;
    }
    this.splitEventsInHalfHourInterval()
    for (const ev of this._events) {
      let hasStarted = ev.dateFrom.getTime() < this._weekDates[0].getTime();
      let hasFinished = ev.dateTo.getTime() < this._weekDates[0].getTime();
      if (hasStarted && hasFinished) {
        continue;
      }
      for (let dayNb = 0; dayNb < 7; dayNb++) {
        const dayStart = this._weekDates[dayNb];
        const dayEnding = this._weekDayEndings[dayNb];
        let startedToday = false;
        let finishedToday = false;
        if (!hasStarted) {
          if (
            ev.dateFrom.getTime() >= dayStart.getTime() &&
            ev.dateFrom.getTime() <= dayEnding.getTime()
          ) {
            hasStarted = true;
            startedToday = true;
          }
        }
        if (!hasFinished) {
          if (
            ev.dateTo.getTime() >= dayStart.getTime() &&
            ev.dateTo.getTime() <= dayEnding.getTime()
          ) {
            hasFinished = true;
            finishedToday = true;
          }
        }

        // is not started yet, therefore jump to the next day
        if (!hasStarted) {
          continue;
        }
        const startedProgress = startedToday
          ? this.timeAsProgress(ev.dateFrom)
          : 0;
        const finishProgress = finishedToday
          ? this.timeAsProgress(ev.dateTo)
          : 100;
        const progressRange = finishProgress - startedProgress;
        this._dayEvents[dayNb].push(
          new EventInternal(startedProgress, progressRange, ev)
        );

        // is finished, skip next days
        if (hasFinished) {
          break;
        }
      }
    }

    this.fixIntersectingWidths();
  }

  splitEventsInHalfHourInterval() {
    let events = []
    const halfHour = this.HOUR_LONG / 2
    for(const ev of this._events) {
      let newEvent = {...ev}
      newEvent.dateFrom = ev.dateFrom
      newEvent.dateTo = new Date(new Date(newEvent.dateFrom).getTime() + halfHour)
      events.push({...newEvent})
      while(new Date(newEvent.dateFrom).getTime() < new Date(new Date(ev.dateTo).getTime() - halfHour).getTime()) {
        newEvent.dateFrom = events[events.length - 1].dateTo
        newEvent.dateTo = new Date(new Date(newEvent.dateFrom).getTime() + halfHour)
        events.push({...newEvent})
      }
    }
    this._events = events
  }


  fixIntersectingWidths() {
    for (let i = 0; i < this._dayEvents.length; i++) {
      const dayEvents = this._dayEvents[i];

      const eventsRangeSet = new RangeSet<EventInternal>();
      dayEvents
        .filter((e) => !e.relatedEntry._narrowIntersected)
        .forEach((e) => {
          e.left = 0;
          e.width = this.EVENT_WIDTH;
        });

      dayEvents
        .filter((e) => e.relatedEntry._narrowIntersected)
        .forEach((e) =>
          eventsRangeSet.addRange(
            e.relatedEntry.dateFrom.getTime(),
            e.relatedEntry.dateTo.getTime(),
            e
          )
        );
      eventsRangeSet.reassingGroups();

      const groupWidth = this.EVENT_WIDTH / eventsRangeSet.groupsNumber;

      dayEvents
        .filter((e) => e.relatedEntry._narrowIntersected)
        .forEach((de) => {
          de.width = groupWidth;
          de.left = eventsRangeSet.getAsigmentByData(de) * groupWidth;
        });
    }
  }

  timeAsProgress(date: Date): number {
    return ((date.getHours() * 60 + date.getMinutes()) / (24 * 60)) * 100;
  }

  moveDays(days: number) {
    const newTime = this.currentDate.getTime() + days * this.DAY_LONG;
    const newDate = new Date();
    newDate.setTime(newTime);
    this.currentDate = newDate;
  }

  today() {
    this.currentDate = new Date();
  }

  public eventClick(column: number, ev: EventInternal, mouseEvent: MouseEvent) {
    const target = mouseEvent.currentTarget as HTMLElement;

    const progressInElement = mouseEvent.offsetY / target.clientHeight;
    const clickDayProgress = (ev.start + ev.height * progressInElement) / 100;

    this.timeSelect.emit(
      new ClickEvent(
        ev.relatedEntry,
        this.progressToDate(this._weekDates[column], clickDayProgress)
      )
    );
    mouseEvent.stopPropagation();
  }

  public dayClick(column: number, ev: MouseEvent) {
    const target = ev.currentTarget as HTMLElement;
    const eventTarget = ev.target as HTMLElement;

    let offset = ev.offsetY;

    // special case - if event is targeted on hour cell - we have to calculate offset relative to it
    if (eventTarget.classList.contains('wcal-day-hour')) {
      offset += eventTarget.offsetTop;
    }

    const timeAsProgress = offset / target.clientHeight;
    this.timeSelect.emit(
      new ClickEvent(
        null,
        this.progressToDate(this._weekDates[column], timeAsProgress)
      )
    );
  }

  private progressToDate(dayDate: Date, progress: number): Date {
    const resultDate = new Date(dayDate);
    const timeInMinutes = progress * (24 * 60);
    const hour = Math.floor(timeInMinutes / 60);
    const minute = Math.floor(timeInMinutes % 60);

    resultDate.setHours(hour);
    resultDate.setMinutes(minute);
    return resultDate;
  }

  ngAfterViewInit(): void {
    const hoursScroll = this.hoursScrollRef.nativeElement;
    const daysScroll = this.daysScrollRef.nativeElement;
    const gridScroll = this.gridScrollRef.nativeElement;
    this.syncScroll(gridScroll, hoursScroll, false);
    this.syncScroll(gridScroll, daysScroll, true);
    this.setupColumnWidth(this.caledar.nativeElement, gridScroll.clientWidth);
    window.addEventListener('resize', () => {
      this.setupColumnWidth(this.caledar.nativeElement, gridScroll.clientWidth);
    });
    this.scrollToFocusDate();
  }

  scrollToFocusDate() {
    if (!this.currentDate) {
      return;
    }

    const gridScroll = this.gridScrollRef.nativeElement as HTMLElement;
    const windowWidth = gridScroll.clientWidth;
    const windowHeight = gridScroll.clientHeight;
    const childBoard = gridScroll.children.item(0);
    const boardHeight = childBoard.clientHeight;
    const boardWidth = childBoard.clientWidth;

    const dayNb = this.currentDate.getDay();
    const horizontalProgress = dayNb / 7;
    const verticalProgress = this.timeAsProgress(this.currentDate) / 100;

    const horizontalScroll = this.calculateScroll(
      horizontalProgress,
      boardWidth,
      windowWidth
    );
    const verticalScroll = this.calculateScroll(
      verticalProgress,
      boardHeight,
      windowHeight
    );

    try {
      gridScroll.scrollTop = verticalScroll;
      gridScroll.scrollLeft = horizontalScroll;
    } catch (e) {}
    this.hoursScrollRef.nativeElement.scrollTop = verticalScroll;
    this.daysScrollRef.nativeElement.scrollLeft = horizontalScroll;
  }

  calculateScroll(progress: number, boardSize: number, windowSize: number) {
    let requestedScroll = Math.round(progress * boardSize - windowSize / 2);
    requestedScroll = requestedScroll > 0 ? requestedScroll : 0;
    return requestedScroll;
  }

  syncScroll(parent: any, child: any, horizontal: boolean) {
    this.fixChildSize(parent, child, horizontal);
    window.addEventListener('resize', () => {
      this.fixChildSize(parent, child, horizontal);
    });
    parent.addEventListener('scroll', function () {
      if (horizontal) {
        child.scrollLeft = parent.scrollLeft;
      } else {
        child.scrollTop = parent.scrollTop;
      }
    });
  }

  fixChildSize(parent: any, child: any, horizontal: boolean) {
    if (horizontal) {
      child.style.width = parent.clientWidth + 'px';
    } else {
      child.style.height = parent.clientHeight + 'px';
    }
  }

  setupColumnWidth(calendar: any, width: number) {
    let columnWidth = width / 7;
    if (columnWidth < 64) {
      columnWidth = 64;
    }
    const gridWidth = columnWidth * 7;

    Array.from(calendar.getElementsByClassName('wc-col-width')).forEach(
      (element: HTMLElement) => {
        element.style.width = columnWidth + 'px';
      }
    );
    Array.from(calendar.getElementsByClassName('wc-width')).forEach(
      (element: HTMLElement) => {
        element.style.width = gridWidth + 'px';
      }
    );
  }

  getHours() {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(`${i.toString().padStart(2, '0')}:00`);
      hours.push(`${i.toString().padStart(2, '0')}:30`);
    }
    return hours;
  }

  getWeekDates() {
    return this._weekDates;
  }

  getCurrentTimeAsProgress() {
    return this._currentTimeAsProgress;
  }

  getActiveDay() {
    return this._activeDay;
  }

  getWeekStartDate() {
    return Utils.getWeekStartDate(this._currentDate);
  }

  getWeekEndDate() {
    return Utils.getWeekEndDate(this._currentDate);
  }

  getCompetences(scheduleName: string){
    return scheduleName.substring(scheduleName.indexOf('[')+1, scheduleName.indexOf(']'))
  }

  getStudentName(event: EventInternal){
    return event.title()?.split(' ')?.length > 2 ? event.title().split(' ')[2] : ''
  }

  getEventTitle(event: EventInternal){
    if(event.classes === "schedule"){
      if(this.isMobile()){
        return this.getStudentName(event)
      }
      return event.relatedEntry.dateFrom.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) + ' ' + this.getStudentName(event)
    }

    const title = event.title() ? event.title().substring(0,event.title().indexOf("[")) : ''
    switch(title){
      case this.lessons.en.label:
        return this.lessons.en.short
      case this.lessons.enBiz.label:
        return this.lessons.enBiz.short
      case this.lessons.sp.label:
        return this.lessons.sp.short
      case this.lessons.enCh.label:
        return this.lessons.enCh.short
      default:
        return ''
    }
  }

  getCourseName(courseCode: string, eventType: string){

    const name = eventType === "worktime" ?  courseCode?.substring(0, courseCode?.indexOf("[")).trimEnd() : courseCode?.substring(0, courseCode?.indexOf("."))

    switch(name){
      case this.lessons.en.label:
        return this.lessons.en.long
      case this.lessons.enBiz.label:
        return this.lessons.enBiz.long
      case this.lessons.enCh.label:
        return this.lessons.enCh.long
      case this.lessons.sp.label:
        return this.lessons.sp.long
      default:
        return ''
    }
  }

  getStage(courseCode: string){
    const index = courseCode.indexOf(" ")
    return courseCode.substring(index-1,index)
  }

  applyHighlights(column: number, ev: MouseEvent){
    const target = ev.currentTarget as HTMLElement;
    const parentTarget = target.offsetParent as HTMLElement;

    let offset = target.offsetTop;
    const timeAsProgress = offset / parentTarget.clientHeight;

    let date: any
    this.highlightedDate = this.progressToDate(this._weekDates[column], timeAsProgress)
    if(this.highlightedDate) date = Dates.roundDateIntoMiddleOfClick(this.highlightedDate)
    this.highlightedHour = date?.getHours().toString().padStart(2, '0') + ":"+ date?.getMinutes().toString().padStart(2, '0')
  }

  removeHighlights() {
    this.highlightedDate = null
    this.highlightedHour = null
  }

  isHighlitedCol(date: Date){
    return this.highlightedDate?.getDate() === date.getDate()
  }

  isHighlitedRow(hour: any){
    return this.highlightedHour == hour
  }
}
