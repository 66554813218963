import {PlatformDescriptor} from "../app/utils/platform-descriptor";

export enum Env {
  DEV, PROD, STAGE
}

export const environment = {
  production: true,
  env: Env.PROD,
  debug: false,

  // ....

  platform: PlatformDescriptor.web,
  casaClientId: 'dh8ahjh9aAIOIOW90hnBjioid5bDKOP89mczxchai898998',
  callanonlineId: 1,
  serverBase: 'https://area.callan.app',
  authEndpoint: 'https://casa.callanonline.com',
  casaEndpoint : 'https://casa.callanonline.com',
  cspaEndpoint: 'https://api.cspa.callanonline.com',
  appEndpoint: 'https://app.callanonline.com',
  awsBase: 'https://s3.amazonaws.com',
  videoEndpoint:'https://api.video.callanonline.com/api',
  videoWebBase:'https://video2.callanonline.com',
  callanAppUrl: "https://callan.app",
  lessonWsEndpoint: 'wss://lessons.callanonline.com/ws',
  lessonEndpoint: 'https://lessons.callanonline.com/api',
  bookingEndpoint: 'https://booking.callanonline.com/api',
  cspaWsEndpoint: 'wss://api.cspa.callanonline.com/cspa-websocket',
  fileEndpoint: 'https://lessons.callanonline.com/files',
  
  oldCallanServerBase: 'https://my.callanonline.com',

  // ...

  sentryTarget: 'https://area.callan.app',
  sentryDsn: "https://fe7ac6315fdf4d00aaaa9b8f51642810@o334018.ingest.sentry.io/4504321124401152",
  logToSentry: true,
  envName: 'web-prod',

    styleClass: 'web',

  colFunctionality: true,
  ssmiEnabled: false,
  scheduleBridgeEnabled: false
}
